import random from "random";

let numPoints = 0;
let inside = 0;
let outside = 0;
let fractionTimes4 = 0;

let running = false;

let r = 255
let g = 0
let b = 0

let numPointsElement;
let insideElement;
let outsideElement;
let fractionElement;
let errorElement;

let numSides = 6;
let sideLength = 1;

let n=0;
let summand = 1;
let sumGeom = 1;
let errorGeom;

function rgbStep () {
  if (r < 255 && g === 0 && b === 0) {
    r++
  } else if (r === 255 && g < 255 && b === 0) {
    g++
  } else if (r > 0 && g === 255 && b === 0) {
    r--
  } else if (r === 0 && g === 255 && b < 255) {
    b++
  } else if (r === 0 && g > 0 && b === 255) {
    g--
  } else if (r < 255 && g === 0 && b === 255) {
    r++
  } else if (r === 255 && g === 0 && b > 0) {
    b--
  }
}

function draw(x, y) {
  const canvas = document.getElementById("canvas");
  const ctx = canvas.getContext("2d");
  const height = canvas.clientHeight;
  const width = canvas.clientWidth;
  ctx.fillStyle = "rgba("+r+","+g+","+b+", 1)";
  ctx.fillRect( (x * width-1), (y * height-1) , 3, 3 );
  if (numPoints % 100 === 1) {
    ctx.beginPath();
    ctx.ellipse(0, height, width, height, 0, 0, Math.PI / 4, true);
    ctx.stroke();
  }
  rgbStep();
}



function update() {
  numPointsElement.innerText = numPoints.toString(10);
  insideElement.innerText = inside.toString(10);
  outsideElement.innerText = outside.toString(10);
  fractionElement.innerText = fractionTimes4.toString(10);
  errorElement.innerText = ((fractionTimes4 - Math.PI) / Math.PI).toString(10);
}

function step() {
  const x = random.float();
  const y = random.float();
  const distanceSquared = x * x + y * y;
  if (distanceSquared >= 1) {
    outside++;
  } else {
    inside++;
  }
  numPoints = inside + outside;
  fractionTimes4 = (4 * inside) / (numPoints);
  draw(x, y);
  update();
}

function loop() {
  if (running) {
    step();
    window.setTimeout(loop, 1);
  }
}

function toggleRun() {
  running = !running;
  if (running) {
    document.getElementById("singleStep").setAttribute("disabled", "");
    document.getElementById("run").innerText = "Stop";
    loop();
  } else {
    document.getElementById("singleStep").removeAttribute("disabled");
    document.getElementById("run").innerText = "Run";
  }
}

function stepArchimedes() {
  const lastRow = document.getElementsByClassName("last").item(0);
  lastRow.classList.remove("last");
  numSides *= 2;
  sideLength = Math.sqrt(2-(Math.sqrt(4-(sideLength*sideLength))));
  const circumference = numSides * sideLength / 2;
  const error = (circumference - Math.PI) / Math.PI;
  const newRow = document.createElement("tr");
  newRow.classList.add("last");
  newRow.innerHTML=`<td>${numSides}</td><td>${sideLength}</td><td>${circumference}</td><td>${error}</td>`;
  lastRow.parentNode.insertBefore(newRow, null);
}

function stepGeometricSequence() {
  const lastRow = document.getElementsByClassName("lastGeom").item(0);
  n++;
  summand = Math.pow(-1, n)/(2*n +1);
  sumGeom += summand;
  const piGeom = 4 * sumGeom;
  errorGeom = (piGeom - Math.PI) / Math.PI;
  lastRow.innerHTML=`<td>${n}</td><td>${summand}</td><td>${piGeom}</td><td>${errorGeom}</td>`;
}

window.addEventListener("load", () => {
  document.getElementById("singleStep").addEventListener("click", step);
  document.getElementById("run").addEventListener("click", toggleRun);
  document.getElementById("stepArchimedes").addEventListener("click", stepArchimedes);
  document.getElementById("stepGeom").addEventListener("click", stepGeometricSequence);
  numPointsElement = document.getElementById("numPoints");
  insideElement = document.getElementById("inside");
  outsideElement = document.getElementById("outside");
  fractionElement = document.getElementById("fraction");
  errorElement = document.getElementById("error");
  document.getElementById("initial").innerText = (3-Math.PI)/Math.PI
  document.getElementById("initialGeom").innerText = (1 - Math.PI)/Math.PI;
});
